<template lang="">
  <div class="row">
    <div class="col-md-3 col-6">
      <Card class="card dveb-list-card dveb-grayheader-card">
        <template #header>
          <div class="card-header-title">
            <p >Yer Gösterim Talep Sayısı</p>
          </div>
        </template>
        <b-list-group class="custom-list-view">
          <router-link to="/declaration">
            <b-list-group-item to="/adverts/find/detail/1/">
              <div class="d-flex justify-content-between">
                <p>Balıkesir</p>
                <b-badge variant="primary">4</b-badge>
              </div>
            </b-list-group-item>
          </router-link>
          <b-list-group-item>
            <div class="d-flex justify-content-between">
              <p>Bursa</p>
              <b-badge variant="primary">4</b-badge>
            </div>
          </b-list-group-item>
          <b-list-group-item>
            <div class="d-flex justify-content-between">
              <p>Bilecik</p>
              <b-badge variant="primary">4</b-badge>
            </div>
          </b-list-group-item>
        </b-list-group>
      </Card>
    </div>
    <div class="col-md-3 col-6">
      <Card class="card dveb-list-card dveb-grayheader-card">
        <template #header> <div class="card-header-title">Emlak Beyan Sayısı</div> </template>
        <b-list-group class="custom-list-view">
          <b-list-group-item>
            <router-link to="/declaration">
              <div class="d-flex justify-content-between">
                <p>Balıkesir</p>
                <b-badge variant="primary">4</b-badge>
              </div>
            </router-link>
          </b-list-group-item>
          <b-list-group-item>
            <div class="d-flex justify-content-between">
              <p>Bursa</p>
              <b-badge variant="primary">4</b-badge>
            </div>
          </b-list-group-item>
        </b-list-group>
      </Card>
    </div>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
  .card-header-title {
    font-size: 1.1rem !important;
  }

</style>
